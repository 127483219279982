/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import Table, { TableCell, TableHeader, TableRow } from '../Table';

import dataHeaderTable from 'assets/dataDummy/table';
import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';
import mapModifiers from 'utils/functions';

export type PrizeTypes = {
  id: number;
  name: string;
  address: string;
  number: number;
}
interface PrizeCollapseProps {
  title: string;
  prizeData: PrizeTypes[];
  modifiers?: ('' | 'active' | 'small' | 'announce')[];
}

const PrizeCollapse: React.FC<PrizeCollapseProps> = ({
  prizeData, title, modifiers,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <div className={mapModifiers('o-prizeCollapse', modifiers, collapsed && 'collapse')}>
      <div
        className="o-prizeCollapse_title"
        onClick={() => setCollapsed(!collapsed)}
      >
        <span>
          {title.toUpperCase()}
        </span>
        {modifiers?.includes('announce') && (
          <div className="o-prizeCollapse_title_hand">
            <Icon iconName="hand" iconSize="32x32" />
          </div>
        )}
      </div>
      <div className="o-prizeCollapse_list">
        <div className="o-prizeCollapse_table">
          <Table
            classModifiers="prize"
            modifiers={modifiers?.includes('small') ? 'small' : ''}
            header={(
              <TableHeader>
                <TableRow isHead>
                  {dataHeaderTable.map((e, i) => (
                    <TableCell isHead key={`tableCell-${i.toString()}`}>
                      {e.value}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHeader>
            )}
          >
            {prizeData.map((item, index) => (
              <TableRow key={`tableRow-${index.toString()}`}>
                <TableCell>
                  <Text modifiers={[modifiers?.includes('small') ? '8x10' : '14x19', 'pigment', 'fontUrmAvo']}>
                    {index + 1}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text modifiers={['fontUrmAvo', modifiers?.includes('small') ? '8x10' : '14x19', '700', 'pigment']}>
                    {item.name}
                  </Text>
                  <div className="u-mt-xl-8 u-mt-4">
                    <Text modifiers={['fontUrmAvo', 'jet', modifiers?.includes('small') ? '8x10' : '14x19', '400']}>
                      {item.address}
                    </Text>
                  </div>
                </TableCell>
                <TableCell>
                  <Text modifiers={['fontUrmAvo', modifiers?.includes('small') ? '8x10' : '14x19', '700', 'pigment']}>
                    {item.number}
                  </Text>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </div>
      </div>
    </div>
  );
};

PrizeCollapse.defaultProps = {
  modifiers: undefined,
};

export default PrizeCollapse;
