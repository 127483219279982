import React from 'react';

import FoyerContainer from 'containers/Foyer';

const Foyer: React.FC = () => (
  <div className="p-foyer">
    {/* <DeviceWrapped> */}
    <FoyerContainer />
    {/* </DeviceWrapped> */}
  </div>
);

export default Foyer;
